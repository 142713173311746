import React from 'react';
import Header from '../components/Header';
import MainSection from '../components/MainSection';
import FeaturesSection from '../components/FeaturesSection';

const Home = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <FeaturesSection />
    </div>
  );
};

export default Home;