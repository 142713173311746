import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false); // State for toggling the menu
  const history = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      history.push('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) {
    return (
      <header className="navbar">
        <a className="logo" href="/">🌙</a>
        <span>Loading...</span>
      </header>
    );
  }

  return (
    <header className="navbar">
      <a className="logo" href="/">🌙</a>
      <div className="menu-container">
        {user ? (
          <>
            {/* Hamburger Menu */}
            <div className='menu-toggle' onClick={() => setMenuOpen(!menuOpen)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={`menu-content ${menuOpen ? 'open' : ''}`}>
              <div className="menu-item">
                <a className='menu-item-text' href="/">Friends (Soon)</a>
              </div>
              <div className="menu-item">
                <a className='menu-item-text' href="/">Profile (Soon)</a>
              </div>
              <hr className="menu-separator" />
              <div className="menu-bottom">
                <span>Welcome, {user.displayName || user.email}</span>
                <img
                  src={user.photoURL || '/first_pfp.png'}
                  alt="User Avatar"
                  className="user-avatar"
                />
                <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
              </div>
            </div>
          </>
        ) : (
          <a className="navbar-button" href="/login">Login</a>
        )}
      </div>
    </header>
  );
};

export default Header;