import React from 'react';
import Header from '../components/Header';
import Downloads from '../components/Downloads';

const Home = () => {
  return (
    <div>
      <Header />
      <Downloads />
    </div>
  );
};

export default Home;